import { useState } from 'react';
import { Card, Col, Row, Alert, Form, Button } from 'antd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import logo from '@/assets/img/l_4.png';
import ForgotForm, { FormBody } from '@/components/forms/ForgotForm';
import { useAuth } from '@/hooks/auth.hook';
import APIService from '@/services/API';
import { HttpError } from '@/interfaces/api.interface';
import { useTranslation } from 'react-i18next';
import ResetForm, { ResetBody } from '@/components/forms/ResetForm';

const ForgotScreen = () => {
  const { token: userToken } = useAuth();
  const { token } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formRef] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<HttpError | undefined>({
    className: '',
    code: 0,
    message: '',
    name: '',
    data: [],
  });

  if (userToken) {
    return <Navigate to="/dashboard" />;
  }

  const onFormSubmit = async (data: FormBody) => {
    setLoading(true);
    const response = await APIService.requestForgot(data.email);
    if (response.ok) {
      if (response.data?.result) {
        setError({
          className: 'Success',
          code: 200,
          message: t('forgot.emailSent'),
          name: 'Success',
          data: [],
        });
        formRef.resetFields();
      }
    } else {
      setError(response?.data);
    }
    setLoading(false);
  };

  const onResetSubmit = async (data: ResetBody) => {
    setLoading(true);
    const response = await APIService.resetPassword(token, data);
    if (response.ok) {
      if (response.data?.result) {
        setError({
          className: 'Success',
          code: 200,
          message: t('forgot.changeSuccess'),
          name: 'Success',
          data: [],
        });
        formRef.resetFields();
      }
    } else {
      setError(response?.data);
    }
    setLoading(false);
  };

  const goToLogin = () => {
    formRef.resetFields();
    navigate('/');
  };

  const showCustomError = (error: HttpError | undefined) =>
    error?.code !== 200
      ? `${error?.message}, ${t('general.errorCode')}: ${error?.code}`
      : `${error?.message}`;

  return (
    <div className="login-screen">
      <div className="cover"></div>
      <Row className="wrapper" justify="center" align="middle">
        <Col xxl={8} xl={8} lg={12} md={18} sm={18} xs={23}>
          <Card>
            <Row justify="center">
              <Col className="logo">
                <img src={logo} alt="Real state" />
              </Col>
            </Row>
            {error?.code !== 0 && (
              <Row>
                <Col span={24} className="alert-container">
                  <Alert
                    type={error?.code !== 200 ? 'warning' : 'success'}
                    message={showCustomError(error)}
                    showIcon
                  />
                </Col>
              </Row>
            )}
            {token ? (
              <ResetForm formRef={formRef} onSubmit={onResetSubmit} loading={loading} />
            ) : (
              <ForgotForm formRef={formRef} onSubmit={onFormSubmit} loading={loading} />
            )}
            <Row justify="center">
              <Col>
                <Button type="link" onClick={goToLogin}>
                  {t('forgot.backLogin')}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotScreen;
