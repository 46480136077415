import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormInstance, Input, Button } from 'antd';

export type FormBody = {
  email: string;
};

export interface ForgotFormProps {
  formRef: FormInstance;
  onSubmit?: (values: FormBody) => Promise<void>;
  loading?: boolean;
}

const ForgotForm: React.FC<ForgotFormProps> = ({ formRef, loading, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('forgot.request')}</h3>
      <Form
        form={formRef}
        name="forgot-form"
        layout="vertical"
        autoComplete="off"
        onFinish={onSubmit}
      >
        <Form.Item
          label={t('login.email')}
          name="email"
          rules={[{ required: true, type: 'email', whitespace: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            style={{ width: '100%' }}
            htmlType="submit"
            size="large"
            loading={loading}
          >
            {t('forgot.reset')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ForgotForm;
